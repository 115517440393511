import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadall,
  loadquotationpdf,
  loadquotation,
  createItem,
  updateQuotation,
} from 'api/quotation'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadall)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationList: response?.data?.quotationlistinfo,
      },
    })
  }
}

export function* SINGLE_QUOTATION({ payload }) {
  const { response } = yield call(loadquotation, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationDetails: response?.data?.quotationDetails,
      },
    })
  }
}

export function* VIEW_PO({ payload }) {
  const { response } = yield call(loadquotationpdf, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        quotationpdfLocationUrl: response?.data?.quotationpdfLocationUrl,
        openproforma: true,
        quotationList: response?.data?.quotationlistinfo,
      },
    })
  }
}

export function* CREATE_QUOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createItem, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayCreateQuotationDrawer: false,
        quotationList: response?.data?.quotationlistinfo,
      },
    })
  }
}

export function* UPDATE_QUOTATION({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateQuotation, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayEditQuotationDrawer: false,
        quotationList: response?.data?.quotationlistinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.VIEW_PO, VIEW_PO),
    takeLatest(actions.CREATE_QUOTATION, CREATE_QUOTATION),
    takeLatest(actions.SINGLE_QUOTATION, SINGLE_QUOTATION),
    takeLatest(actions.UPDATE_QUOTATION, UPDATE_QUOTATION),
  ])
}
