import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadall() {
  return apiClient
    .get(`${BASE_URL}/loadinvoice`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createItem(payload) {
  return apiClient
    .post(`${BASE_URL}/createinvoice/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadinvoicepdf(payload) {
  return apiClient
    .post(`${BASE_URL}/getinvoicepdf/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadinvoice(payload) {
  return apiClient
    .post(`${BASE_URL}/loadinvoicedetails`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateInvoice(payload) {
  return apiClient
    .post(`${BASE_URL}/editinvoice/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
