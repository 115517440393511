import apiClient from 'services/axios'

const BASE_URL = 'Login'

export async function loadall() {
  return apiClient
    .get(`${BASE_URL}/loadbills`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function createItem(payload) {
  return apiClient
    .post(`${BASE_URL}/createbillinvoices/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateQuotation(payload) {
  return apiClient
    .post(`${BASE_URL}/editquotation/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadquotationpdf(payload) {
  return apiClient
    .post(`${BASE_URL}/getbillpdf/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadquotation(payload) {
  return apiClient
    .post(`${BASE_URL}/loadquotationdetails/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadsinglebill(payload) {
  return apiClient
    .post(`${BASE_URL}/loadsinglebill/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateBill(payload) {
  return apiClient
    .post(`${BASE_URL}/updateBill/`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
