import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs } from 'antd'
import { Link } from 'react-router-dom'
import IndividualAccountsAction from 'redux/singleaccounts/actions'
import AccountDetail from './AccountDetail'
import NotesInfo from './NotesInfo'
import Invoices from './Invoices'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, singleaccounts }) => ({
  dispatch,
  singleaccounts,
})

const StoreInfo = ({ dispatch, singleaccounts, intl: { formatMessage } }) => {
  const path = window.location.pathname
  const userId = path.replace('/manage/accounts/', '')

  const AccountDatas = singleaccounts?.IndividualAccountData

  useEffect(() => {
    dispatch({
      type: IndividualAccountsAction.LOAD_SINGLE_ACCOUNTS,
      payload: {
        accountsId: userId,
      },
    })
  }, [dispatch, userId])

  const query = new URLSearchParams(window.location.search)
  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'accounts-info') {
    tabUrlVal = '1'
  } else if (tabname === 'notes') {
    tabUrlVal = '2'
  } else if (tabname === 'invoices') {
    tabUrlVal = '3'
  }

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.accounts',
        })}
      />
      <div className="row">
        <div className="col-md-6">
          <h5 className="mb-4">
            <strong>{AccountDatas?.business_name}</strong>
          </h5>
        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=accounts-info">
              <span>{formatMessage({ id: 'text.storeino.tab.accountsInfo' })}</span>
            </Link>
          }
          key={1}
        >
          <AccountDetail apiRes={AccountDatas} />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=notes">
              <span>{formatMessage({ id: 'text.storeino.tab.notesInfo' })}</span>
            </Link>
          }
          key={2}
        >
          <NotesInfo />
        </TabPane>

        <TabPane
          tab={
            <Link to="?tab=invoices">
              <span>{formatMessage({ id: 'text.storeino.tab.invoices' })}</span>
            </Link>
          }
          key={3}
        >
          <Invoices />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
