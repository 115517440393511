export default async function getMenuData() {
  return [
    {
      title: 'Manage Accounts',
      key: 'manage-accounts',
      icon: 'fe fe-file',
      url: '/manage/accounts',
    },
    {
      title: 'Manage Users',
      key: 'manage-users',
      icon: 'fe fe-user',
      url: '/manage/user',
    },
    {
      title: 'Manage Invoices',
      key: 'manage-invoice',
      icon: 'fe fe-settings',
      url: '/manage/invoice',
    },

    {
      title: 'Manage Quotation',
      key: 'manage-quotation',
      icon: 'fe fe-settings',
      url: '/manage/quotation',
    },
    {
      title: 'Manage Bill',
      key: 'manage-bill',
      icon: 'fe fe-settings',
      url: '/manage/bill',
    },
  ]
}
