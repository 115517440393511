import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadall, loadinvoicepdf, updateInvoice, loadinvoice, createItem } from 'api/invoice'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadall)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoiceList: response?.data?.invoicelistinfo,
      },
    })
  }
}

export function* VIEW_PO({ payload }) {
  const { response } = yield call(loadinvoicepdf, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoicepdfLocationUrl: response?.data?.invoicepdfLocationUrl,
        invoiceList: response?.data?.invoicelistinfo,
        openproforma: true,
      },
    })
  }
}

export function* CREATE_INVOICE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: true,
    },
  })
  const { response } = yield call(createItem, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      creating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayCreateInvoiceDrawer: false,
        invoiceList: response?.data?.invoicelistinfo,
      },
    })
  }
}

export function* SINGLE_INVOICE({ payload }) {
  const { response } = yield call(loadinvoice, payload)
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        invoiceDetails: response?.data?.invoiceDetails,
      },
    })
  }
}

export function* UPDATE_INVOICE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { response } = yield call(updateInvoice, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.SET_STATE,
      payload: {
        displayEditInvoiceDrawer: false,
        invoiceList: response?.data?.invoicelistinfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.VIEW_PO, VIEW_PO),
    takeLatest(actions.CREATE_INVOICE, CREATE_INVOICE),
    takeLatest(actions.UPDATE_INVOICE, UPDATE_INVOICE),
    takeLatest(actions.SINGLE_INVOICE, SINGLE_INVOICE),
  ])
}
